export default defineNuxtRouteMiddleware(async (to) => {
    const isInstalled = ref(useIntegrationInstalled(to.path.split('/')[5]));
    const { user } = storeToRefs(userSessionStore());

    const { data: integrations } = await useFetch(`/api/users/${user.value?.id}/integrations`, {
        key: `integrations-${user.value?.id}`,
        headers: useRequestHeaders(['cookie']),
    });
    if (to.path.endsWith('/integration')) {
        if (integrations.value?.length && integrations.value.length > 0) {
            return navigateTo(`/dashboard/settings/connect/integration/${integrations.value[0].integration.name}`);
        }
    }

    if (!to.path.endsWith('/integration')) {
        if (!isInstalled) {
            return navigateTo('/dashboard/settings/connect/integration');
        }
    }
});
